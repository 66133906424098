import * as React from "react"
import Button from "../components/Button"

import Layout from "../components/layout"
import Section from "../components/Section"
import opengraph from "../images/opengraph.png"

const NotFoundPage = () => (
  <Layout pageTitle="404: Not found" image={opengraph}>
    <Section>
      <h1>404: Oops, something went wrong </h1>
      <p>Unfortunately, this page doesn't exist.</p>
      <Button primary url="/">
        Return Home
      </Button>
    </Section>
  </Layout>
)

export default NotFoundPage
